import * as React from 'react'

import GoogleReviews, { ColoredStar } from '../components/Reviews'
import Faqs from '../components/Faqs'
import Layout from '../components/Layout'
import { graphql, navigate, Link } from 'gatsby'
import styled from 'styled-components'

import { Grid } from '@material-ui/core'
import { secondary } from '../../../src/constants/theme'

import ButtonWithArrow from '../../../src/components/ButtonWithArrow'
import Hero from '../components/Hero'
import { content } from '../../site.config'
const Main = styled.main`
    margin: 0 auto;
    max-width: 1160px;
    padding: 0 12px;
    height: 80vh;
    display: flex;
    @media (max-width: 767px), (orientation: portrait) {
        height: auto;
    }
    p.MuiFormHelperText-root.Mui-error {
        font-size: 0.6rem;
        text-align: left;
    }
    h1 > span {
        color: ${secondary};
    }
`

const HeroImageWrapper = styled.div`
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 80vh;
    img {
        height: 100%;
        width: 100%;
    }

    @media (max-width: 767px) {
        height: 130vw;
    }

    &:before {
        background: #55411aa3;
        content: ' ';
        position: absolute;
        z-index: 1;
        height: 80vh;
        width: 100%;
    }
    @media (max-width: 767px), (orientation: portrait) {
        width: auto;
        &:before {
            height: 100%;
        }
        img {
            height: 100%;
        }
    }
`

const Section = styled.section`
    margin: 70px auto;
    padding: 0 12px;
    max-width: 960px;
    text-align: center;
    h2 {
        font-weight: 600;
        font-size: 2.5rem;
    }
    p {
        font-size: 1.6rem;
        margin: 0 auto;
        max-width: 600px;
    }
`

const FaqSection = styled(Section)`
    h3 {
        font-size: 1.7rem;
    }
    h4 {
        font-size: 1.4rem;
    }
    h3,
    h4 {
        font-weight: 600;
        padding: 25px 0 10px 0;
    }
    h3,
    h4,
    p,
    ul,
    li {
        text-align: left;
    }
    p,
    li {
        font-size: 1.1rem;
        margin: 0;
        max-width: 100%;
    }
    ul li {
        list-style: disc;
        margin-left: 25px;
        line-height: 1.5;
    }
`

const TopGrid = styled(Grid)`
    color: white;
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    min-height: 500px;
    h1 {
        font-weight: 700;
        font-size: 3rem;
        line-height: 1.05;
    }
    h2 {
        font-weight: 600;
        font-size: 2rem;
    }
    h1,
    h2,
    p {
        text-align: center;
    }
    p {
        font-size: 1.2rem;
    }
    > div.MuiGrid-root > div > a > div {
        > button {
            border-radius: 0;
        }
    }
    @media (min-width: 767px) {
        > div.MuiGrid-root > div > a > div {
            > img {
                right: -21px;
                width: 60px;
            }
        }
    }
    @media (max-width: 767px) {
        h1 {
            font-size: 2rem;
        }
        h2 {
            font-size: 1.2rem;
        }
        > div {
            :nth-child(1) {
                display: flex;
                justify-content: center;
                flex-direction: column;
                margin-top: 15px;
            }
            :nth-child(2) {
                //margin-top: 80px;
                //background-color: white;
            }
        }
    }
`
const TopFormContainer = styled(Grid)`
    > div > div {
        min-height: 400px;
        padding: 60px 20px;
        @media (max-width: 767px) {
            padding: 20px;
        }
    }
`
const BottomFormContainer = styled.div`
    && {
        div > div > img {
            right: 100px;
            @media (max-width: 767px), (orientation: portrait) {
                right: -12px;
            }
        }
    }
    > div > a > div {
        > button {
            border-radius: 0;
        }
    }
    @media (min-width: 767px) {
        > div > a > div {
            > img {
                width: 60px !important;
            }
        }
    }
`

const Overlay = styled.div``

const submitCallback = ({ query }) => {
    navigate(`https://jurigo.ca${query}`)
}

const IndexPage = ({ location }) => {
    return (
        <Layout location={location} noPhones={true}>
            <Hero alternativeImage={true} to="/demande-avocat/" />
            <Section>
                <h2>JuriGo.ca le comparateur d’avocats #1 au Québec</h2>
                <p>{content.find}</p>
            </Section>
            <Section>
                <GoogleReviews />
            </Section>
            <FaqSection>
                <Faqs />
            </FaqSection>
            <Section>
                <h2 style={{ fontSize: '1.4rem' }}>{content.answer}</h2>
                <BottomFormContainer>
                    <div>
                        <Link to="/demande-avocat/">
                            <ButtonWithArrow>
                                Trouver mon avocat
                            </ButtonWithArrow>
                        </Link>
                    </div>
                </BottomFormContainer>
            </Section>
        </Layout>
    )
}

export default IndexPage

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
